// Set default contact information to empty slots if not provided
export const mapPatientData = data => {
    return {
        ...data,
        legalRepresentative: {
            ...data.legalRepresentative,
            email: data?.legalRepresentative?.email ?? "",
            mobileNumber: data?.legalRepresentative?.mobileNumber ?? "",
        },
        email: data?.email || "",
        mobileNumber: data?.mobileNumber || "",
    };
};
