/* eslint-disable import/named */
import React, { useState, useEffect } from "react";
import { Formiz, useForm } from "@formiz/core";
import { isRequired } from "@formiz/validations";
import {
  Box,
  Grid,
  Select,
  FormControl,
  MenuItem,
  Typography,
  Button,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { CobaltIcon } from "da-design-system";
import { useTranslation } from "react-i18next";
import CAIHField from "../../components/CAIHField";
import CAIHCard from "../../components/CAIHCard";
import CAIHDatepicker from "../../components/CAIHDatepicker";
import OtpModal from "../../components/login/OtpModal";
import FieldValidationUtil from "../../utils/FieldValidationUtil";
import ModalUtil from "../../utils/ModalUtil";
import ClinicService from "../../services/ClinicService";
import ConsentService from "../../services/ConsentService";
import Layout from "../../components/Layout";
import SnackbarUtil from "../../utils/SnackbarUtil";
import IppTooltip from "../../components/login/IppTooltip";
import ConsentLoginErrorBlock from "../../components/consentLogin/ConsentLoginErrorBlock";
import { isPatientReachable } from "../../utils/patients/isPatientReachable";
import { mapPatientData } from "../../services/mappers/mapPatientData";
import { getPatientInformations } from "../../utils/patients/getPatientData";
import { LOGIN_ERROR_TYPES } from "../../constants/loginErrorTypes";
import AuthService from "../../services/AuthService";

function Login() {
  const { t } = useTranslation();
  const history = useHistory();
  const authentForm = useForm();
  const setModal = ModalUtil.useModal()[1];
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [clinics, setClinics] = useState([]);
  const [chosenClinic, setChosenClinic] = useState("");
  const [authentErrorType, setAuthentErrorType] = useState(null);

  const setSnackbar = SnackbarUtil.useSnackbar()[1];

  useEffect(() => {
    ClinicService.getClinics().then(response => {
      const { data } = response;
      const clinicsArray = [];

      data.forEach(clinicObject => {
        const item = JSON.parse(clinicObject.value);
        const { shortName, hidden } = item;

        if (!hidden) {
          clinicsArray.push({
            healthFacilityId: clinicObject.k1,
            shortName,
          });
        }
      });

      const sortedClinics = clinicsArray.sort((a, b) =>
        // eslint-disable-next-line no-nested-ternary
        a.shortName > b.shortName ? 1 : b.shortName > a.shortName ? -1 : 0,
      );

      setClinics(sortedClinics);
      setIsLoading(false);
    });
  }, []);

  const changeClinic = event => {
    const { value } = event.target;
    setChosenClinic(value === "" ? null : value);
  };

  const resetSubmissionState = () => {
    setIsFetching(false);
    setAuthentErrorType(null);
  };

  const handleSubmit = () => {
    resetSubmissionState();
    const { ipp, birthDate } = getPatientInformations(authentForm);

    ConsentService.getPatientByBirthdate(birthDate, ipp, chosenClinic)
      .then(({ data }) => {
        const patientWithDefaultContact = mapPatientData(data);
        const { cantReach, has2FADisabled } = isPatientReachable(
          patientWithDefaultContact,
        );

        if (has2FADisabled && !cantReach) {
          AuthService.setDataConnection(patientWithDefaultContact);
          return history.push("/consents");
        }

        setIsFetching(false);

        if (cantReach) return setAuthentErrorType("UNREACHABLE");

        return setModal({
          isOpen: !cantReach && !has2FADisabled,
          title: t("login:modalTitle"),
          hasSeparator: true,
          content: <OtpModal user={patientWithDefaultContact} />,
          backdropReason: null,
        });
      })
      .catch(error => {
        const { response } = error;
        const {
          data: { errorMessage },
        } = response || null;

        const isAuthError =
          errorMessage === "PATIENT_NOT_FOUND" ||
          errorMessage === "TOO_MANY_ATTEMPTS";

        setAuthentErrorType(errorMessage);
        setIsFetching(false);

        setSnackbar({
          isOpen: !isAuthError,
          message: "Une erreur est survenue. Merci de réessayer.",
          type: "error",
        });
      });
  };

  return (
    <Layout noHeader>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ height: "80vh" }}
      >
        <Grid item xs={12} sm={10}>
          <Box mb={7}>
            <Typography variant="h1">{t("login:title")}</Typography>
          </Box>
          <CAIHCard>
            <Formiz connect={authentForm} onValidSubmit={handleSubmit}>
              <Box mb={4}>
                <Typography variant="subtitle1">
                  {t("login:subtitle")}
                </Typography>
              </Box>
              <Box mb={2}>
                <Grid item sm={6}>
                  <CAIHField
                    required
                    name="ipp"
                    fullWidth
                    label={t("login:ippLabel")}
                    toolTip={{
                      icon: <CobaltIcon name="help-circle" />,
                      content: <IppTooltip />,
                    }}
                    validations={[
                      {
                        rule: isRequired(),
                        message: t("form:error.required"),
                      },
                    ]}
                  />
                </Grid>
              </Box>
              <Box mb={2}>
                <Grid item sm={6}>
                  <FormControl variant="outlined" required fullWidth>
                    <Box mb={2}>
                      <Typography variant="body1">
                        {t("login:clinicLabel")}
                      </Typography>
                    </Box>
                    <Select
                      labelId="clinic-select-label"
                      id="clinic-select"
                      fullWidth
                      onChange={changeClinic}
                      value={chosenClinic}
                      input={<Select />}
                    >
                      <MenuItem
                        aria-label="empty"
                        value={null}
                        sx={{ height: "36px" }}
                      />
                      {!isLoading &&
                        clinics.map(clinic => (
                          <MenuItem
                            key={clinic.healthFacilityId}
                            value={clinic.healthFacilityId}
                          >
                            {clinic.shortName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Box>
              <Box mb={2}>
                <Grid item sm={6}>
                  <CAIHDatepicker
                    name="birthdate"
                    disabledPart="FUTURE"
                    required
                    fullWidth
                    validations={[
                      {
                        rule: isRequired(),
                        message: t("form:error.required"),
                      },
                    ]}
                  />
                </Grid>
              </Box>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
              >
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  endIcon={<CobaltIcon name="arrow-right" />}
                  disabled={
                    !authentForm.isValid ||
                    !FieldValidationUtil.isDateValid(
                      authentForm.values.birthdate,
                    ) ||
                    !chosenClinic ||
                    isFetching
                  }
                >
                  {t("login:connect")}
                </Button>
              </Grid>
            </Formiz>
          </CAIHCard>

          {LOGIN_ERROR_TYPES.includes(authentErrorType) && (
            <Box mt={2} mb={2}>
              <ConsentLoginErrorBlock errorType={authentErrorType} />
            </Box>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
}

export default Login;
