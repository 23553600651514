export const isPatientReachable = patientData => {
    // Check user data with no 1FA config
    const isPatientUnreachable =
        patientData.email === "" && patientData.mobileNumber === "";
    const isLegalRepresentativeUnreachable =
        patientData.legalRepresentative.email === "" &&
        patientData.legalRepresentative.mobileNumber === "";

    // Check user with 1FA config
    const has2FADisabled = patientData?.authenticationMode !== "2FA" || false;
    const isUnreachable = patientData?.authenticationMode === "NONE" || false;

    return {
        cantReach:
            (isPatientUnreachable && isLegalRepresentativeUnreachable) ||
            isUnreachable,
        has2FADisabled,
    };
};
